tr.MuiTableRow-root {
    th: {
        border: none;
    }

    &:hover {
        box-shadow: inset 0 0 1px 0px #00000036;
    }

    &:first-of-type {
        background: white;
        &:hover {
            box-shadow: none;
        }

        td.MuiTableCell-root {
            color: #b3b3b3;
            font-size: 12px;
            font-weight: 500;
            padding: 0 10px;
            border: none;
            height: 38px;
            text-transform: uppercase;
        }
    }

    &:nth-child(even) {
        background: #f7f7f7;
    }

    &:nth-child(odd) {
        background: white;
    }

    td:first-of-type {
        font-weight: bold;
    }

    td.MuiTableCell-root {
        background: none;
        font-size: 16px;
        padding: 0 10px;
        border: none;
        height: 64px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        * {
            font-size: 16px;
            text-transform: capitalize;
        }
    }
}
