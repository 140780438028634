.searchbar {
    .MuiFilledInput-root {
        height: 56px;
        border-radius: 0px;
    }
    .MuiFilledInput-root:hover {
        background: none;
    }
    .MuiFilledInput-underline {
        height: 56px;

        &::before {
            display: none;
        }
    }
    .MuiFilledInput-adornedEnd {
        background: none;
    }
}
