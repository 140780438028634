@import 'template/layout', 'template/button', 'template/link', 'template/header',
    'template/popover', 'template/searchbar', 'template/table', 'template/menu',
    'template/misc', 'template/accordion', 'template/variables';

* {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    /* overflow-y: scroll; */
    font-family: 'static-bold', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'static-bold';
    src: local('static-bold'),
        url(../assets/fonts/Static-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'dosis-semibold';
    src: local('dosis-semibold'),
        url(../assets/fonts/Dosis-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'open-sans';
    src: local('open-sans'),
        url(../assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

code {
    font-family: monospace;
}

span.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
    border: 1px solid #cfcfcf;
}

.high-risk,
.high-risk .MuiLinearProgress-bar {
    background-color: rgb(172, 81, 159);
}

.medium-risk,
.medium-risk .MuiLinearProgress-bar {
    background-color: rgb(147, 149, 152);
}

.low-risk,
.low-risk .MuiLinearProgress-bar {
    background-color: rgb(190, 183, 68);
}

.high-risk-score {
    color: rgb(172, 81, 159) !important;
    background: transparent;
}

.medium-risk-score {
    color: rgb(147, 149, 152) !important;
    background: transparent;
}

.low-risk-score {
    color: rgb(190, 183, 68) !important;
    background: transparent;
}

span.MuiBadge-root {
    position: absolute;
}

span.MuiBadge-badge {
    transform: none !important;
    padding: 15px 8px;
    background: #00a79d;
    margin-left: 10px;
    top: -15px;
    font-weight: bold;
    border-radius: 4px;
    white-space: nowrap;
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate {
    height: 30px;
    border-radius: 4px 25px 25px 4px;
}

.progress-container {
    padding: 5px;
    position: relative;
}

.progress-container .progress-title-container {
    position: absolute;
    left: 5px;
    top: 5px;
    bottom: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.MuiTabs-root {
    border-bottom: 1px solid #adadad;
    box-shadow: inset 0px -3px 2px 0px #0000000a;
}

.MuiDialogContent-root {
    overflow-y: initial !important;
}

.MuiTypography-caption {
    right: initial !important;
    position: initial !important;
    width: fit-content !important;
    background: white;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 1px 1px #80808038;
}

.MuiInput-underline:before {
    display: none;
}

.expandable-container {
    display: flex;
}

.expandable-panel {
    padding-right: 32px;
}

.transition-opacity {
    transition: opacity 0.5s ease-in-out;
}

.MuiTypography-caption {
    font-size: 16px !important;
}

.expandable-container {
    flex-direction: column;
}

.expandable-panel {
    margin: 30px 0;
}

.visibility-buttons-container {
    justify-content: center;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    min-height: 80px;
}

#results-page-container {
    max-width: $layoutwidth;
    margin: auto;
}

.sticky-container {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    overflow-x: auto;
    z-index: 10;
}

.sticky-container.sticky {
    position: fixed;
    top: 110px;
}

.sticky-content {
    background: white;
    margin: auto;
}

.printable.printing {
    min-width: 900px !important;
    max-width: 900px !important;
    padding-top: 15px;
}

.printable.printing * {
    animation: none !important;
}

.printable.printing > div {
    background: white !important;
    padding: 0 !important;
    border: 2px solid rgb(225 225 225) !important;
}

.printable.printing > div > div {
    background: white !important;
    padding: 20px !important;
}

@media (max-width: 768px) {
    .sticky-container {
        position: fixed !important;
        top: initial !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
    }

    .sticky .sticky-content {
        transform: none;
    }

    .visibility-buttons-container {
        min-height: 0;
        padding: 10px !important;
    }

    .expandable-container {
        margin-bottom: 60px !important;
    }
}

.loader {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 100;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes rotation {
    from {
        transform: rotate(359deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.rotate {
    animation: rotation 1s infinite ease-out;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-button {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
.printable {
  min-width: 1024px !important;
  max-width: 1024px !important;
} */

span.deactivated {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 1;
}

.MuiGrid-container {
    display: flex;
    align-items: center;
}

#login-form-container {
    position: relative;
    max-width: 1100px;
    height: calc(100vh - 69px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    #dynamic-form-container {
        height: 330px;
        width: 500px;
        position: relative;
        margin: auto;
    }
}

.recorder-timer {
    font-family: monospace;
    padding: 6px;
    border: 1px solid #e0e0e0;
    margin: 8px 0;
    border-radius: 5px;
    background: #f1f3f4;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
