* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    background-color: #00a79d;
}
.container {
    background-color: transparent;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
}
input[type='range'] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 100%;
    height: 8px;
    border-radius: 8px;
    outline: none;
    background: rgb(1, 227, 0);
    background: linear-gradient(
        90deg,
        rgb(190, 183, 68) 0%,
        rgb(147, 149, 152) 50%,
        rgb(172, 81, 159) 100%
    );
}
input[type='range']::-webkit-slider-runnable-track {
    display: none;
    opacity: 0;
}
input[type='range']::-moz-track {
    display: none;
    opacity: 0;
}
input[type='range']::-ms-track {
    display: none;
    opacity: 0;
}
input[type='range']::-webkit-slider-thumb {
    display: none;
    opacity: 0;
}
input[type='range']::-moz-range-thumb {
    display: none;
    opacity: 0;
}
input[type='range']::-ms-thumb {
    display: none;
    opacity: 0;
}
input[type='range']:active::-webkit-slider-thumb {
    display: none;
    opacity: 0;
}

.slider-value {
    position: relative;
    background-color: #00a79d;
    color: #ffffff;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    padding: 10px 5px;
    border-radius: 5px;
    min-width: fit-content;
}
.container > * {
    margin: 0 6px;
}

.slider-container {
    position: relative;
    min-width: 100px;
    width: 80%;
}

.pointer {
    position: absolute;
    height: 0px;
    border-top: 18px solid #00a79d;
    width: 12px;
    display: block;
    bottom: 10px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    margin-left: 8px;
}

@media (max-width: 640px) {
    .container {
        flex-direction: column-reverse;
    }

    .slider-value {
        margin-bottom: 25px;
    }
}
