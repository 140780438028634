@import 'variables';

section.top-bar {
    height: $topbarheight;

    header.MuiAppBar-root {
        position: fixed;
        background: transparent;
        background-color: #00a79d;
        box-shadow: 0px 3px 3px 0px #00000042;

        .top-bar-inner-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: auto;
            padding: 0 20px;
            max-width: $layoutwidth;

            .logo-container {
                max-height: 100%;
                max-width: 50%;
                display: flex;
                align-items: center;
            }

            .header-actionable-containers {
                max-height: 100%;
                max-width: 50%;
                display: flex;
                align-items: center;

                > * {
                    margin-left: 10px;
                }
            }

            .MuiInputBase-root.MuiInput-root {
                color: white;

                &.MuiInput-underline:before {
                    border: none;
                }

                &.Mui-focused {
                    background: none;
                }

                .MuiSelect-icon {
                    color: white;
                    width: 1.3rem;
                    padding-bottom: 4px;
                }
            }
        }
    }
}
