.wrapper {
    display: table;
    margin: 0 auto;
    background: rgba(21, 34, 46, 0.25);
    padding: 35px 40px 40px;
    border-radius: 6px;
    box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.32), 0 1px 9px -1px #15222e;
}

.wrapper-header {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    color: rgba(255, 255, 255, 0.74);
    margin: 30px 0 15px;
    font-weight: normal;
}

.heart {
    margin: 0 8px;
    display: inline-block;
    animation: simplePulse ease-in-out 1.1s infinite;
}

.gauge {
    margin: 55px auto;
    font-family: Arial, Helvetica, sans-serif;
    background: #e7e7e7;
    box-shadow: 0 0 0 6px rgb(255 255 255 / 9%),
        0 0 35px 5px rgb(255 255 255 / 29%);
    width: 200px;
    float: left;
    height: 100px;
    border-radius: 100px 100px 0 0;
    position: relative;
    overflow: hidden;
    transform-style: flat;
    transform: scale(2);
}

.gauge-center {
    color: #fff;
    width: 60%;
    height: 60%;
    background: black;
    border-radius: 100px 100px 0 0;
    position: absolute;
    left: 20%;
    bottom: 0;
    box-shadow: 0 -13px 15px -10px rgb(0 0 0 / 28%);
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: column;
}

.gauge-center > .title {
    font-size: 12px;
}

.needle {
    width: 78px;
    height: 7px;
    background: #15222e;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 100%;
    border-top-right-radius: 5px;
    position: absolute;
    bottom: 0px;
    left: 22px;
    transform-origin: 100% 4px;
    box-shadow: 0 2px 2px 1px rgb(0 0 0 / 38%);
}

.slice-colors .st-container {
    background: rgb(51, 51, 51);
    background: radial-gradient(
        circle,
        rgb(161 161 161) 0%,
        rgb(255 255 255) 40%
    );
    background-size: 39px 326px;
    background-position: center;
}

.slice-colors .st,
.slice-colors .st-container {
    position: absolute;
    height: 100px;
    left: calc(50% - 30px);
    bottom: 9px;
    transform-origin: bottom center;
    width: 60px;
    -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%, 50% 100%);
    clip-path: polygon(0 0, 100% 0, 50% 100%, 50% 100%);
}

.slice-colors .st-container p {
    text-align: center;
    font-size: 9px;
    padding: 18px 5px;
    font-weight: bold;
}

.low {
    transition: transform 1s;
    transform: rotate(-70deg);
    animation: speed 1.5s ease-in-out;
}
.medium {
    transition: transform 1s;
    transform: rotate(0deg);
    animation: speed 1.5s ease-in-out;
}
.high {
    transition: transform 1s;
    transform: rotate(70deg);
    animation: speed 1.5s ease-in-out;
}

.gauge-center .label {
    font-size: 12px;
}

.needle-container {
    position: absolute;
    left: calc(50% - 32px);
    transition: transform 1s;
    transform-origin: bottom;
    height: 75%;
    width: 64px;
    bottom: 0px;
    margin-bottom: 15%;
}

.gauge-container {
    position: relative;
    min-width: 240px;
    max-width: 500px;
    width: 100%;
}

.text-container {
    position: absolute;
    bottom: 25%;
    color: white;
    width: 100%;
}

.text-container > * {
    font-size: 40px !important;
    bottom: -14px;
    padding: 0 100px;
}

.legend {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 5%;
    justify-content: space-around;
    color: white;
}

.legend * {
    font-size: 15px !important;
    width: 30%;
    height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

@media (max-width: 425px) {
    .legend * {
        font-size: 11px !important;
        height: 20px;
    }

    .text-container * {
        font-size: 20px !important;
        bottom: -10px;
        padding: 0 50px;
    }
}

@keyframes speed {
    0% {
        transform: rotate(-95deg);
    }
    50% {
        transform: rotate(90deg);
    }
}
