button {
    &.MuiButton-root {
        height: 40px;
        border: none;
        color: white;
        box-shadow: none;
        border-radius: 8px;
        white-space: nowrap;
        background: #00a79d;
        text-transform: capitalize;
        font-weight: 500;

        &.MuiButton-sizeLarge {
            height: 56px;
        }

        &.inverted {
            color: #00a79d;
            background: white;
        }

        &.MuiButton-contained {
            border: 2px solid #00a79d;
            background: white;
            color: #00a79d;

            &.inverted {
                background: #00a79d;
                color: white;
            }

            &:hover {
                color: #00a79d;
                border: 2px solid #00a79d;
                background: white;
            }

            &:disabled {
                border: 1px solid #cccccc;
                color: #cccccc;
                background: white;
            }
        }

        &.MuiButton-text {
            color: #02877c;
            font-weight: 600;
            background: none;
            border: none;
            transition: color 0.5s;

            &.inverted {
                color: white;

                &:hover {
                    color: #e0e0e0;
                    box-shadow: none;
                    background: none;
                    transition: color 0.25s;
                }
            }

            &:hover {
                color: #57a39d;
                box-shadow: none;
                background: none;
            }

            &:disabled {
                color: #e7e7e7;
            }
        }

        &:hover {
            box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 17%);
            background: #00a79d;
        }

        &:active {
            background: #72c0ba;
        }

        &:disabled {
            background: #cccccc;
            border: none;
        }

        span.MuiButton-label {
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: center;
        }
    }
}
