.expandable-panel-control {
  min-width: 64px;
  min-height: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  overflow-x: auto;
  transition: max-width 0.5s ease-in-out !important;
}

.expandable-label-container {
  max-width: 64px;
  min-width: 64px;
  min-height: 100%;
  background: white;
  position: absolute;
  display: flex;
  align-items: flex-start;
  z-index: 2;
  top: 64px;
  border: 1px solid #dfdfdf;
}

.expandable-label-container .typography-container {
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  height: 435px;
  white-space: nowrap;
}

.expandable-label-container .typography-container label {
  line-height: 52px !important;
}

.not-expanded {
  max-width: 64px;
  overflow-x: hidden;
}

.expanded {
  max-width: 570px;
}

.children-container {
  margin-left: 64px;
}

/* @media (max-width: 1380px) { */
  .expandable-panel-control {
    transition: max-height 0.5s ease-in-out !important;
    max-height: 64px;
    min-height: 64px;
    min-width: 100%;
  }

  .expandable-label-container {
    max-width: 100%;
    min-width: calc(100% - 64px);;
    min-height: 64px;
    top: 0;
    left: 64px;
    flex-direction: row;
    align-items: center;
  }

  .expandable-label-container .typography-container {
    transform: none;
    height: initial;
    max-width: fit-content;
    writing-mode: initial;
  }

  .not-expanded {
    max-height: 64px;
  }

  .expanded {
    max-height: 1600px;
  }

  .children-container {
    margin-left: 0;
  }
/* } */
