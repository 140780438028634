@import 'variables';

.MuiAccordion-root.navigation-accordion {
    &:before {
        display: none;
    }

    &.Mui-expanded {
        margin: 0;
    }

    width: 100%;
    max-height: 88px;
    line-height: 60px;
    border-radius: 0;
    border-bottom: 2px solid #f2f2f2;
    transition: width 0.15s linear;

    &.MuiAccordion-rounded {
        border-radius: 0 !important;
    }

    .MuiAccordionSummary-root {
        padding: 0;

        .MuiAccordionSummary-content {
            label {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

.MuiAccordion-root.navigation-accordion {
    .navigation-accordion-content {
        top: 58px;
        left: 50vw;
        width: 50%;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 10;
        padding: 10px 30px;
        position: fixed;
        overflow: auto;
        max-width: 550px;
        background: white;
        box-shadow: -1px 0px 4px 0 rgb(0 0 0 / 18%);

        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .MuiAccordion-root.navigation-accordion {
        .navigation-accordion-content {
            width: 100%;
            max-width: $layoutwidth;
            left: 0;
        }
    }
}

.navigation-accordion.MuiAccordion-root.Mui-disabled {
    background-color: transparent;
}
